import * as React from 'react'
const TextBoxSearch = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: 'new 0 0 24 24'
    }}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M15.5 12c2.5 0 4.5 2 4.5 4.5 0 .9-.2 1.7-.7 2.4l3.1 3.1-1.4 1.4-3.1-3.1c-.7.4-1.5.7-2.4.7-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5m0 2c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5M5 3h14c1.1 0 2 .9 2 2v8c-.5-.8-1.2-1.5-2-2V5H5v14h4.5c.3.8.8 1.4 1.3 2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2m2 4h10v2H7V7m0 4h5c-.8.5-1.5 1.2-2 2H7v-2m0 4h2.2c-.1.5-.2 1-.2 1.5v.5H7v-2z"
      style={{
        fill: '#ff5723'
      }}
    />
  </svg>
)
export default TextBoxSearch
