import * as React from 'react'
const Shield = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: 'new 0 0 24 24'
    }}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M12 1 3 5v6c0 5.5 3.8 10.7 9 12 5.2-1.3 9-6.5 9-12V5l-9-4m0 4c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3m5.1 12c-1.2 1.9-3 3.2-5.1 3.9-2.1-.7-3.9-2.1-5.1-3.9L6 15.5c0-1.7 2.7-3 6-3s6 1.3 6 3c-.2.5-.5 1-.9 1.5z"
      style={{
        fill: '#ff5723'
      }}
    />
  </svg>
)
export default Shield
