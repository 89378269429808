import React from 'react'
import { graphql, Link } from 'gatsby'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import Layout from 'layouts/index'
import Seo from 'components/seo'
import TextBoxSearch from '../components/icon/textBoxSearch'
import Shield from '../components/icon/shield'
import CheckboxIcon from '../components/icon/checkbox-multiple'

const LawEnforcementPage = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <React.Fragment>
        <Seo
          seo={{
            metaTitle: t('lawEnforcement.meta.title'),
            // metaDescription: t('lawEnforcement.meta.description'),
            metaUrl: 'https://www.mlytics.com/law-enforcement/',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
              imageWidth: 1200,
              imageHeight: 630,
              imageType: 'image/jpeg'
            }
          }}
        />

        <section className="bg-primary-500  ">
          <div className="container flex flex-col md:flex-row pt-16 md:pt-20 pb-10">
            <div className="flex-1 flex flex-col md:items-center  md:block ">
              <h1 className=" text-white mb-5 md:mb-8 text-xl md:text-[3rem] leading-snug">
                {t('lawEnforcement.section1.title')}
              </h1>
              <p className=" pt-2 text-start text-sm leading-8 md:text-[1rem] text-white mb-5">
                {t('lawEnforcement.section1.paragraph1')}
              </p>
              ``
              <p className=" pt-2 text-start text-sm leading-8 md:text-[1rem] text-white ">
                {t('lawEnforcement.section1.paragraph2')}
              </p>
            </div>
            <div className="flex flex-1 items-center overflow-hidden">
              <img
                src="https://www.mlytics.com/wp-content/uploads/2022/09/Law-enforcement_hero_04.svg"
                alt={t('lawEnforcement.section1.title')}
                className="max-w-full md:max-w-[550px]"
                style={{ height: 'auto' }}
              />
            </div>
          </div>
        </section>
        <section className="container pb-20 py-10 px-12 md:px-5">
          <h2 className="text-primary-500 text-center  mb-8 hidden  md:block">{t('lawEnforcement.section2.title')}</h2>
          <h3 className="text-primary-500 text-center mb-8  block md:hidden">{t('lawEnforcement.section2.title')}</h3>
          <div className="md:flex ">
            <div className="flex-1 md:mr-16 mb-10 md:mb-0">
              <div className="mb-4">
                <h3 className="text-primary-500 flex items-center">
                  <CheckboxIcon className="mr-3 w-10 " />
                  {t('lawEnforcement.section2.request1.title')}
                </h3>
                <p className="text-gray-700 pt-5 text-start text-base ">
                  {t('lawEnforcement.section2.request1.paragraph1')}
                </p>
                <p className="text-gray-700 pt-5 text-start text-base">
                  {t('lawEnforcement.section2.request1.paragraph2')}
                </p>
              </div>
              <div>
                <h3 className="text-primary-500 items-center flex mt-10">
                  <TextBoxSearch className="mr-3  w-10 " />
                  {t('lawEnforcement.section2.request2.title')}
                </h3>
                <p className="text-gray-700 pt-5 text-start text-base">
                  {t('lawEnforcement.section2.request2.paragraph1')}
                </p>
                <p className="text-gray-700 pt-5 text-start text-base">
                  {t('lawEnforcement.section2.request2.paragraph2')}
                </p>
              </div>
            </div>
            <div className="flex-1">
              <div>
                <h3 className="text-primary-500 items-center flex">
                  <Shield className="mr-3  w-10 " />
                  {t('lawEnforcement.section2.request3.title')}
                </h3>
                <p className="text-gray-700 pt-5 text-start text-base">
                  {t('lawEnforcement.section2.request3.paragraph1')}
                </p>
                <p className="text-gray-700 pt-5 text-start text-base">
                  {t('lawEnforcement.section2.request3.paragraph2')}
                </p>
                <p className="text-gray-700 pt-5 text-start text-base">
                  {t('lawEnforcement.section2.request3.paragraph3')}
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-gray-50 pt-10 md:pt-20 pb-20">
          <div className="container px-12 md:px-[8%]">
            <h2 className="md:mt-12 md:text-4xl text-primary-500 mb-5 text-center w-full text-2xl">
              {t('lawEnforcement.section3.title')}
            </h2>
            <p className="text-gray-700 leading-7 mt-5">{t('lawEnforcement.section3.content')}</p>
            <h2 className="text-3xl md:text-4xl text-primary-500 mb-[50px] text-center w-full mt-20">
              {t('lawEnforcement.section4.title')}
            </h2>
            <p className="text-gray-700 leading-7 mt-5">
              <Trans i18nKey={'lawEnforcement.section4.content'}>
                <Link
                  to="/terms"
                  className="text-secondary-600 hover:text-secondary-800 transition-all duration-300"
                ></Link>
                <Link
                  to="/privacy"
                  className="text-secondary-600 hover:text-secondary-800 transition-all duration-300"
                ></Link>
              </Trans>
            </p>
          </div>
        </section>
        <section className=" pt-[120px] pb-[120px] ">
          <div className="container px-12  md:px-[8%]">
            <h2 className="text-3xl md:text-4xl text-primary-500 mb-[50px] text-center w-full">
              {t('lawEnforcement.section5.title')}
            </h2>
            <p className="text-gray-700 leading-7 mt-5">
              <Trans i18nKey={'lawEnforcement.section5.content'}>
                <a
                  href="mailto: abuse+law@mlytics.com"
                  className="text-secondary-600 hover:text-secondary-800 transition-all duration-300"
                ></a>
              </Trans>
            </p>
          </div>
        </section>
      </React.Fragment>
    </Layout>
  )
}

export default LawEnforcementPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
