import * as React from 'react'
const CheckboxIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: 'new 0 0 24 24'
    }}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M20 16v-6h2v6c0 1.1-.9 2-2 2H8c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h8v2H8v12h12m-9.1-8.9 3.1 3.1 6.6-6.6L22 5l-8 8-4.5-4.5 1.4-1.4M16 20v2H4c-1.1 0-2-.9-2-2V7h2v13h12z"
      style={{
        fill: '#ff5723'
      }}
    />
  </svg>
)
export default CheckboxIcon
